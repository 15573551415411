import React, {useEffect} from 'react';
import './App.css';

import Panel from './Panel';

const English = () => {
    
  const gtmConfig = 'https://www.principality.co.uk';

  useEffect(() => {
    window.parent.postMessage('pageview~/virtual/cy/principality/CompletionDay-checklist', gtmConfig);

    setInterval(() => {
      window.parent.postMessage(document.body.scrollHeight, gtmConfig);
    }, 250);
  }, []);

  return (

    <div className="App">
    <div className="checklist">
      <h1>Rhestr wirio ddiwrnod cwblhau</h1>
      <div className="intro">
        <p>Awgrymiadau i baratoi ar gyfer diwrnod cwblhau’r gwerthiant.</p>
      </div>

      <div className="panelsWrapper">

        <Panel
          title="Cynllunio ymlaen llaw"
          panelKey={'CCpanelOneCY'}
          langCode={'/cy/'}
          completeText={'Marc fel cyflawn'}
          incompleteText={'Marc fel anghyflawn'}
        >
          <p>Os nad ydych yn bwriadu symud allan tan y diwrnod cwblhau, gwnewch yn siŵr eich bod yn cymryd yr holl gamau angenrheidiol i symud allan yn brydlon ar y diwrnod. Paciwch eich holl eiddo ymlaen llaw a gadewch cyn lleied â phosibl i’w wneud ar ddiwrnod cwblhau’r gwerthiant.</p>
          <p>Gwnewch yn siŵr eich bod wedi archebu eich cwmni symud ymhell o flaen llaw, gan gytuno ar ddyddiadau ac amseroedd, os ydych yn defnyddio cwmni symud.</p>

        </Panel>

        <Panel
          title="Sicrhau bod yr arian yn barod i fynd"
          panelKey={'CCpanelTwoCY'}
          langCode={'/cy/'}
          completeText={'Marc fel cyflawn'}
          incompleteText={'Marc fel anghyflawn'}
        >
          <p>Fel arfer, bydd eich cyfreithiwr trawsgludo yn ymdrin â’r taliadau ac yn cydgysylltu hyn gyda’ch benthyciwr. Fodd bynnag, mae’n bosibl y bydd yn rhaid i chi dalu ffioedd cyfreithiwr neu asiant eiddo ar ôl diwrnod cwblhau’r gwerthiant.</p>
          <p>Gwnewch yn siŵr bod yr arian ar gyfer yr holl daliadau y mae’n ofynnol i chi eu gwneud yn barod i fynd.</p>
        </Panel>

        <Panel
          title="Bod ar gael i bobl gysylltu â chi"
          panelKey={'CCpanelThreeCY'}
          langCode={'/cy/'}
          completeText={'Marc fel cyflawn'}
          incompleteText={'Marc fel anghyflawn'}
        >
          <p>Efallai y bydd angen i’ch cyfreithiwr trawsgludo neu eich asiant eiddo gysylltu â chi ar ddiwrnod cwblhau’r gwerthiant, felly gwnewch yn siŵr eich bod yn gysylltadwy drwy’r diwrnod cyfan. Gwnewch yn siŵr bod batri eich ffôn symudol wedi’i wefru a’i gadw’n uchel a gyda chi drwy’r dydd.</p>
          <p>Hefyd, mae’n syniad da cadw copi o fanylion cyswllt eich cyfreithiwr a’ch asiant eiddo, rhag ofn y bydd angen unrhyw beth ar frys ar y diwrnod.</p>
        </Panel>

        <Panel
          title="Paratoi’r holl ddogfennau angenrheidiol"
          panelKey={'CCpanelFourCY'}
          langCode={'/cy/'}
          completeText={'Marc fel cyflawn'}
          incompleteText={'Marc fel anghyflawn'}
        >
          <p>Cadwch yr holl ddogfennau pwysig sy’n ymwneud â gwerthiant eich tŷ a gwnewch yn siŵr eu bod yn rhywle y gallwch gael gafael arnynt ar y diwrnod cwblhau. I fod yn saff, gallech gadw copïau wedi’u hargraffu yn ogystal â chopïau rhithwir ar USB.</p>
        </Panel>

        <Panel
          title="Cadarnhau amseroedd ymlaen llaw"
          panelKey={'CCpanelFiveCY'}
          langCode={'/cy/'}
          completeText={'Marc fel cyflawn'}
          incompleteText={'Marc fel anghyflawn'}
        >
          <p>Gwnewch yn siŵr eich bod yn cadarnhau amseroedd bras cyn i’r diwrnod cwblhau ddechrau. Pan fyddwch yn gwybod ble mae eich safle yn y gadwyn dai, gofynnwch i’ch cyfreithiwr am amseroedd disgwyliedig a chynlluniwch eich diwrnod o amgylch hyn.</p>
          <p>Os ydych yn symud i gartref newydd, cyd-gysylltwch yr amser y byddwch yn gallu symud i mewn â’r amser y byddwch yn symud allan, fel na chewch eich gadael i aros.</p>
        </Panel>
      </div>
    </div>
  </div>

  )
};

export default English;