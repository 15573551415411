import React, {useEffect} from 'react';
import './App.css';

import Panel from './Panel';

const English = () => {
    
  const gtmConfig = 'https://www.principality.co.uk';

  useEffect(() => {
    window.parent.postMessage('pageview~/virtual/principality/CompletionDay-checklist', gtmConfig);

    setInterval(() => {
      window.parent.postMessage(document.body.scrollHeight, gtmConfig);
    }, 250);
  }, []);

  return (

    <div className="App">
      <div className="checklist">
        <h1>Completion day checklist</h1>
        <div className="intro">
          <p>You've nearly sold your home and completion day is creeping up.</p>
          <p>Follow our checklist and make your completion day as easy as possible.</p>
        </div>

        <div className="panelsWrapper">

          <Panel
            title="Plan ahead"
            panelKey={'CCpanelOneEN'}
            completeText={'Mark as complete'}
            incompleteText={'Mark as incomplete'}
          >
            <p>If you don’t plan to move out until completion day, make sure you take all necessary steps to move out promptly on the day. Pack all of your belongings beforehand and leave very little left to do on completion day.</p>
            <p>Make sure you have booked your removal company well ahead of time and dates and times are agreed, if you’re using one.</p>
          </Panel>

          <Panel
            title="Ensure the funds are ready to go"
            panelKey={'CCpanelTwoEN'}
            completeText={'Mark as complete'}
            incompleteText={'Mark as incomplete'}
          >
            <p>Usually, your conveyancing solicitor will handle the payments and coordinate this with your lender. However, you may have to pay solicitor or estate agent fees on completion day.</p>
            <p>Make sure the funds for all payments you are required to make are ready to go.</p>
          </Panel>

          <Panel
            title="Be contactable"
            panelKey={'CCpanelThreeEN'}
            completeText={'Mark as complete'}
            incompleteText={'Mark as incomplete'}
          >
            <p>Your conveyancing solicitor or estate agent may need to contact you on completion day, so make sure you are contactable for the whole day. Charge your mobile phone and keep it on loud and with you all day.</p>
            <p>It’s also a good idea to keep a copy of your solicitor and estate agent's contact details, in case anything is needed urgently on the day.</p>
          </Panel>

          <Panel
            title="Prepare all necessary documents"
            panelKey={'CCpanelFourEN'}
            completeText={'Mark as complete'}
            incompleteText={'Mark as incomplete'}
          >
            <p>Keep all important documents relating to your house sale and make sure they are somewhere you can access them on completion day. To be safe, you could keep printed copies as well as virtual copies on a USB.</p>
          </Panel>

          <Panel
            title="Confirm timings in advance"
            panelKey={'CCpanelFiveEN'}
            completeText={'Mark as complete'}
            incompleteText={'Mark as incomplete'}
          >
            <p>Make sure you confirm approximate timings before completion day begins. Once you know your position in the house chain, ask your solicitor for expected timings and plan your day around this.</p>
            <p>If you’re moving to a new home, coordinate the time you will be able to move in with the time you move out, so you’re not left waiting.</p>
          </Panel>
        </div>
      </div>
    </div>
  )
};

export default English;