import React from 'react';
import './App.css';

import English from './English';
import Cymraeg from './Cymraeg';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

const App = () => {

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <English/>
        </Route>
        <Route>
          <Cymraeg/>
        </Route>
      </Switch>
    </Router>
  )
};

export default App;